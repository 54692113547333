import React, { useState, useEffect, useRef, useContext,useCallback } from "react";
import Header from "./Header";
import MessageInput from './MessageInput';
import ChatMessage from "./ChatMessage"; 
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from '@mui/material'; 
import { useProfilePic } from '../context/ProfilePicContext'; 
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import "./FinalBody.css";
import AuthContext from '../AuthContext';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { BASE_URL } from "../config";
import 'react-h5-audio-player/lib/styles.css'; // Import default styles
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import AlertMessage from './AlertMessage'; // Import the new component
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getSessionData, saveSessionData } from '../utils/session';
import useAutoLogoff from '../useAutoLogoff';
import { useLanguage } from '../context/LanguageContext';
import NavBar from "./NavBar_home";
import CustomAudioPlayer from "./CustomAudioPlayer";
import CustomAudioPlayerAutoPlay from "./CustomAudioPlayerAutoPlay";
import { useTimer } from '../context/TimerContext';
import {fetchPreviousChat, fetchFreshChat,checkCentralSubscription } from "../api";
import img from '../Gif/baia-walking-animation.gif'
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ReactMemo from 'react';
import ImageRenderer from './ImageRenderer';
import { removeEmojis } from '../utils';






const FinalBody = ({ className = "" }) => {
useAutoLogoff(10*60*1000);

const currentLanguage=sessionStorage.getItem('current_language')
// console.log(currentLanguage)
 
  const getEndpoints = (language) => {
    switch (language) {
      case 'French':
        return {
          fresh: 'api/fresh_french_chat',
          continuous: 'api/present_continue_french_chat',
        };
      case 'Spanish':
        return {
          fresh: 'api/fresh_spanish_chat',
          continuous: 'api/present_continue_spanish_chat',
        };
      case 'Hebrew':
        return {
          fresh: 'api/fresh_hebrew_chat',
          continuous: 'api/present_continue_hebrew_chat',
         
        };
      case 'English':
      default:
        return {
          fresh: 'api/fresh_chat',
          continuous: 'api/present_continue_chat',
        };
    }
  };
  const streaming_endpoint = currentLanguage === 'Hebrew' ? 'api/stream_chat_response_hebrew' : 'api/chat_response_stream';
  const { fresh, continuous } = getEndpoints(currentLanguage);
 
  const [freshChatMessages, setFreshChatMessages] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingText, setLoadingText] = useState('Starting Fresh chat BAIA is analyzing previous sessions. Please be patient; this may take 20 to 45 second');
  const { userInfo } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [freshChatActive, setFreshChatActive] = useState(false);
  const [previousChatActive, setPreviousChatActive] = useState(false);
  const [characterCode, setCharacterCode] = useState("BAIA-F-01_English-US");
  const chatBodyRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { profilePic, setProfilePic } = useProfilePic();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true); 
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [showAssessmentAlert, setShowAssessmentAlert] = useState(false);
  const [pendingAssessments, setPendingAssessments] = useState([]);
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const [missingProfileData, setMissingProfileData] = useState([]);
  const chatType=localStorage.getItem('chatType');
  const voice_char = localStorage.getItem('voiceCharacter');
  // console.log(voice_char)
  const sessionData = getSessionData();
  const user_name = sessionData?.username;
  const [streaming, setStreaming] = useState(false);
  const[load, setLoading]=useState(false)
  const flag=localStorage.getItem('flag')
  // const voiceChar=localStorage.getItem('voiceCharacter')
  const email_id=sessionData?.email

  const voiceConsent = JSON.parse(sessionStorage.getItem('consent'));
   
  const details=JSON.parse(sessionStorage.getItem('central_subscription'))
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  // console.log('central subscription details:- ', details, user_name);
  const [showConsentAlert, setShowConsentAlert] = useState(false);
  const handleCloseConsentAlert = () => setShowConsentAlert(false);

  //  Add session for auto audio 
  const [isAutoAudioEnabled, setIsAutoAudioEnabled] = useState(
    JSON.parse(sessionStorage.getItem('autoAudioEnabled')) || false // Initialize from session
  );

  // const isSubscribed = details.subscription_status === true ||  initial_details.Chat_analysis===true.


  useEffect(() => {
    const consent = sessionStorage.getItem('consent');
    if ((details.product_name=='PRO' || details.product_name=='ADVACED' || initialcasualChatStatus) && consent === 'false') {
      setShowConsentAlert(true);
    }
  }, []);
  useEffect(() => {


    const profileAlertTimeout = setTimeout(() => {
      checkProfileAlert();
    }, 10000); 
    return () => clearTimeout(profileAlertTimeout);
  }, []); 

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const checkAssessmentAlert = () => {
    fetch(`${BASE_URL}/api/assessment_alert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_name: user_name 
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('Assessment Alert Response:', data);
        const assessmentsAlert = data.response.assessments_alert;
        const assessmentsNotGiven = data.response.assessments_not_given_by_user;
        if (assessmentsAlert && assessmentsNotGiven.length > 0) {
          const transformedAssessments = assessmentsNotGiven.map(assessment => {
            return assessment
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
          });
          setPendingAssessments(transformedAssessments);
          
          setShowAssessmentAlert(true);
        } else {
          setShowAssessmentAlert(false);
        }
      })
      .catch(error => {
        console.error('Error fetching assessment alert:', error);
      });
  };

  const checkProfileAlert = () => {
    fetch(`${BASE_URL}/api/profile_alert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_name: user_name
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('Profile Alert Response:', data);
        const profileAlert = data.response.profile_alert;
        const missingData = data.response.missing_data;

        if (profileAlert && missingData.length > 0) {
          const transformedMissingData = missingData.map(field => {
            return field
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
          });
          setMissingProfileData(transformedMissingData);
          setShowProfileAlert(true);
        } else {
          setShowProfileAlert(false);
        }
      })
      .catch(error => {
        console.error('Error fetching profile alert:', error);
      });
  };

  const handleCloseAssessmentAlert = () => { setShowAssessmentAlert(false);};
  const handleCloseProfileAlert = () => {setShowProfileAlert(false);};

 

  useEffect(() => {
    const fetchSubscriptionDetails = () => {
      const availableSubscription = JSON.parse(localStorage.getItem('available_subscription'));
      if (availableSubscription) {
        const productName = JSON.parse(localStorage.getItem('product_name'));
        const subscriptionStatus = JSON.parse(localStorage.getItem('subscription_status'));
        setSubscriptionDetails({ productName, subscriptionStatus });
        setIsSubscribed(true);
        checkAssessmentAlert();
        const interval = setInterval(() => {
          checkAssessmentAlert();
        }, 10 * 60 * 1000); 
        return () => clearInterval(interval); 
      }
    };
    fetchSubscriptionDetails();
  }, []); 

  // const getAudioPlayerIndices = (messages) => {
  //   let indices = [];
  //   let count = 0;
    
  //   for (let i = messages.length - 1; i >= 0; i--) {
  //     if (messages[i].display_message && messages[i].role=='assistant') {
  //       indices.push(i);
  //       count++;
  //     }
  //     if (count === 2) {
  //       // console.log(indices)
  //       break;}
  //   }
  
  //   return indices;
  // };
  const getAudioPlayerIndices = (messages) => {
    let indices = [];
    let count = 0;

    // Check if messages is an array to avoid errors
    if (!Array.isArray(messages)) {
        console.warn("Expected messages to be an array, but received:", messages);
        return indices; // Return empty array if messages is not an array
    }

    // Iterate through messages in reverse order
    for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i]; // Store the current message for easier reference

        // Check if message exists and has the properties we need
        if (message && message.display_message && message.role === 'assistant') {
            indices.push(i);
            count++;
        }

        // Stop after finding two indices
        if (count === 2) {
            break;
        }
    }

    return indices; // Return the found indices
};

  
  useEffect(() => {
    

    const loadAndUpdateMessages = async (chatType) => {
      const storageKey = chatType === 'fresh' ? 'freshChat' : 'previousChat';
      // const storedMessages = JSON.parse(sessionStorage.getItem(storageKey)) || [];
      let storedMessages = sessionStorage.getItem(storageKey);

      // Check if storedMessages is valid JSON
      if (storedMessages) {
        try {
          storedMessages = JSON.parse(storedMessages);
        } catch (error) {
          console.error('Error parsing stored messages:', error);
          storedMessages = [];
        }
      } else {
        storedMessages = [];
      }
    
      const indicesToUpdate = getAudioPlayerIndices(storedMessages);
      // console.log({chatType,storedMessages})
      // console.log("index",indicesToUpdate)
      const messagesToUpdate = indicesToUpdate.map(index => storedMessages[index]);
      // console.log(messagesToUpdate)
      const updatedMessages = await updateMessagesWithAudio(messagesToUpdate);
      // console.log(updatedMessages)
      indicesToUpdate.forEach((index, i) => {
        storedMessages[index] = updatedMessages[i];
      });
      // console.log("storedMessages:-  ",storedMessages)
      setMessages(storedMessages)
      sessionStorage.setItem(storageKey, JSON.stringify(storedMessages));
    }
    const updateMessagesWithAudio = async (messages) => {
      const updatedMessages = await Promise.all(messages.map(async (message) => {
        if (message.role === 'assistant') {
          try {
            const ttsPayload = {
              text: message.content,
              character_voice_code: voice_char
            };
  
            const response = await fetch(`${BASE_URL}/api/text-to-speech`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(ttsPayload),
            });
  
            if (!response.ok) {
              throw new Error('Text to speech API request failed');
            }
  
            const blob = await response.blob();
            const newAudioUrl = URL.createObjectURL(blob);
            return { ...message, audioUrl: newAudioUrl };
          } catch (error) {
            console.error('Error converting text to speech:', error);
            return { ...message, audioUrl: null };
          }
        } else {
          return message;
        }
      }));
  
      return updatedMessages;
    };
    loadAndUpdateMessages(chatType)
  }, []); 
  
  
  // const [isRenderingState, setIsRenderingState] = useState(false); // For re-render triggering
  const isRenderingRef = useRef(false); // For persistent reference
  useEffect(() => {
    const isRendering = sessionStorage.getItem('isRendering');
    if (isRendering === 'true') {
      isRenderingRef.current = true;
      // setIsRenderingState(true);
    } else {
      isRenderingRef.current = false;
      // setIsRenderingState(false);
    }
  }, []);

  

  const checkIfDict = (msg) => {
    return msg && typeof msg === 'object' && !Array.isArray(msg); // Correctly closed parentheses
};

const handleSendMessage = useCallback(async (message) => {
    isRenderingRef.current = true;


    console.log("message:- ", message)
    // Determine if message is a dictionary
    let newUserMessage;

    // if (checkIfDict(message)) {
    //     newUserMessage = { content: message.imageUrl, role: 'user' }; // Assign value if message is a dict
    // } else {
    //     newUserMessage = { content: message, role: 'user' }; // Assign value if message is not a dict
    // }

    if (message.imageUrl && !message.text) {
      // Case 1: Only imageUrl exists
      newUserMessage = { content: message.imageUrl, role: 'user' };
      } else if (!message.imageUrl && message.text) {
          // Case 2: Only text exists
          newUserMessage = { content: message.text, role: 'user' };
      } else if (message.imageUrl && message.text) {
          // Case 3: Both imageUrl and text exist
          newUserMessage = { content: message.imageUrl + ' ' + message.text, role: 'user' };
      }

    // console.log("newUserMessage:- ", newUserMessage);

    const updatedMessages = [...messages, newUserMessage];
    setMessages(updatedMessages);
    setLoading(true);

    // Send the message to the server
    try {
        const response = await fetchWithRetry(`${BASE_URL}/${streaming_endpoint}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/event-stream"
            },
            body: JSON.stringify({ messages: updatedMessages })
        });

        console.log({ messages: updatedMessages });
        
        if (!response.body) {
            console.error('Readable stream not supported');
            setLoading(false);
            return;
        }
        
        // console.log("response.body :- ", response.body);
        await handleStreamingResponse(response, updatedMessages, message);

    } catch (error) {
        console.error('Error sending message:', error);
        setLoading(false);
    } finally {
        sessionStorage.setItem('isRendering', 'false');
        isRenderingRef.current = false;
        setLoading(false); // Ensure loading is set to false in the finally block
    }
}, [messages]); // Dependency on messages

const fetchWithRetry = async (url, options, retries = 3) => {
    for (let i = 0; i < retries; i++) {
        try {
            return await fetch(url, options);
        } catch (error) {
            console.error(`Fetch attempt ${i + 1} failed:`, error);
            if (i === retries - 1) throw error; // If it's the last attempt, throw the error
            await new Promise(res => setTimeout(res, 1000)); // Wait before retrying
        }
    }
};
const handleStreamingResponse = async (response, updatedMessages,message) => {
   

    
  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');

  let buffer = '';
  let fullAssistantMessage = '';
  let assistantMessageIndex = updatedMessages.length;

  while (true) {
    // console.log("Main",isRenderingRef.current)
    // if (!isRenderingRef.current) {
    //   // Close the reader to stop reading
    //   reader.cancel();
    //   break;
    // }
    const { done, value } = await reader.read();
    if (done) break;

    buffer += decoder.decode(value, { stream: true });

    let boundary = buffer.indexOf('\n');
    while (boundary !== -1) {
      const chunk = buffer.slice(0, boundary).trim();
      buffer = buffer.slice(boundary + 1);
      boundary = buffer.indexOf('\n');

      if (chunk.startsWith('data:')) {
        setLoading(false);
        await new Promise(resolve => setTimeout(resolve, 90));
        
        const dataStr = chunk.replace('data: data:', '').trim();
        // console.log(dataStr)
        if (dataStr) {
          try {
            // console.log(dataStr)
            const data = JSON.parse(dataStr);
            const text = data.choices[0]?.delta?.content || '';
            if (!isRenderingRef.current) {
              reader.cancel();
              break;
            }
            if (text) {
              fullAssistantMessage += text;
              setMessages(prevMessages => {
                const newMessages = [...prevMessages];
                newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
                return newMessages;
              });
            }
          } catch (error) {
            console.error('Error parsing chunk data:', error);
          }

        }
      }
    }
  }

  if (buffer.trim()) {
    try {
      const data = JSON.parse(buffer.trim().replace('data: data:', ''));
      const text = data.choices[0]?.delta?.content || '';
      if (text) {
        fullAssistantMessage += text;
        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
          return newMessages;
        });
      }
    } catch (error) {
      console.error('Error parsing final buffer data:', error);
    }
  }

  const finalMessages = [...updatedMessages];
  finalMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  setMessages(finalMessages);
  // setIsRendering(false);

  sessionStorage.setItem(
    'chatMessages',
    JSON.stringify(finalMessages.filter((msg) => msg.role === 'user' || msg.role === 'assistant'))
  );

  // console.log("Reached to update:- 1")

  await updateConversation(fullAssistantMessage, message);
  if (voiceConsent) {
    await convertTextToSpeech(fullAssistantMessage, assistantMessageIndex, finalMessages);
  }
};

  // const handleStreamingResponse = async (response, updatedMessages,message) => {
    

      
  //   const reader = response.body.getReader();
  //   const decoder = new TextDecoder('utf-8');

  //   let buffer = '';
  //   let fullAssistantMessage = '';
  //   let assistantMessageIndex = updatedMessages.length;

  //   while (true) {
  //     // console.log("Main",isRenderingRef.current)
  //     // if (!isRenderingRef.current) {
  //     //   // Close the reader to stop reading
  //     //   reader.cancel();
  //     //   break;
  //     // }
  //     const { done, value } = await reader.read();
  //     if (done) break;

  //     buffer += decoder.decode(value, { stream: true });

  //     let boundary = buffer.indexOf('\n');
  //     while (boundary !== -1) {
  //       const chunk = buffer.slice(0, boundary).trim();
  //       buffer = buffer.slice(boundary + 1);
  //       boundary = buffer.indexOf('\n');

  //       if (chunk.startsWith('data:')) {
  //         setLoading(false);
  //         await new Promise(resolve => setTimeout(resolve, 90));

  //         console.log('Original chunk:', chunk);
          
  //         const dataStr = chunk.replace('data: data:', '').replace('data:','').trim();
  //         // const dataStr = chunk.replace(/^data:\s*/, '').trim();

  //         // console.log(dataStr)
  //         if (dataStr) {
  //           try {
  //             console.log("dataStr--", dataStr)
  //             const data = JSON.parse(dataStr);
  //             console.log("data :- ", data)
  //             const text = data.choices[0]?.delta?.content || '';
  //             if (!isRenderingRef.current) {
  //               reader.cancel();
  //               break;
  //             }
  //             if (text) {
  //               fullAssistantMessage += text;
  //               setMessages(prevMessages => {
  //                 const newMessages = [...prevMessages];
  //                 newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  //                 return newMessages;
  //               });
  //             }
  //           } catch (error) {
  //             console.error('Error parsing chunk data:', error);
  //           }

  //         }
  //       }
  //     }
  //   }

  //   if (buffer.trim()) {
  //     try {
  //       const data = JSON.parse(buffer.trim().replace('data: data:', ''));
  //       const text = data.choices[0]?.delta?.content || '';
  //       if (text) {
  //         fullAssistantMessage += text;
  //         setMessages(prevMessages => {
  //           const newMessages = [...prevMessages];
  //           newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  //           return newMessages;
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error parsing final buffer data:', error);
  //     }
  //   }

  //   const finalMessages = [...updatedMessages];
  //   finalMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  //   setMessages(finalMessages);
  //   // setIsRendering(false);

  //   sessionStorage.setItem(
  //     'chatMessages',
  //     JSON.stringify(finalMessages.filter((msg) => msg.role === 'user' || msg.role === 'assistant'))
  //   );

  //   await updateConversation(fullAssistantMessage, message);
  //   if (voiceConsent) {
  //     await convertTextToSpeech(fullAssistantMessage, assistantMessageIndex, finalMessages);
  //   }
  // };


// const handleStreamingResponse = async (response, updatedMessages,message) => {
   

    
//   const reader = response.body.getReader();
//   const decoder = new TextDecoder('utf-8');

//   let buffer = '';
//   let fullAssistantMessage = '';
//   let assistantMessageIndex = updatedMessages.length;

//   while (true) {
//     // console.log("Main",isRenderingRef.current)
//     // if (!isRenderingRef.current) {
//     //   // Close the reader to stop reading
//     //   reader.cancel();
//     //   break;
//     // }
//     const { done, value } = await reader.read();
//     if (done) break;

//     buffer += decoder.decode(value, { stream: true });

//     let boundary = buffer.indexOf('\n');
//     while (boundary !== -1) {
//       const chunk = buffer.slice(0, boundary).trim();
//       buffer = buffer.slice(boundary + 1);
//       boundary = buffer.indexOf('\n');

//       if (chunk.startsWith('data:')) {
//         setLoading(false);
//         await new Promise(resolve => setTimeout(resolve, 90));

//         // console.log('Original chunk:', chunk);
        
//         const dataStr = chunk.replace('data: data:', '').replace('data:','').trim();
//         // const dataStr = chunk.replace(/^data:\s*/, '').trim();

//         // console.log(dataStr)
//         if (dataStr) {
//           try {
//             // console.log("dataStr--", dataStr)
//             const data = JSON.parse(dataStr);
//             // console.log("data :- ", data)
//             const text = data.choices[0]?.delta?.content || '';
//             if (!isRenderingRef.current) {
//               reader.cancel();
//               break;
//             }
//             if (text) {
//               fullAssistantMessage += text;
//               setMessages(prevMessages => {
//                 const newMessages = [...prevMessages];
//                 newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
//                 return newMessages;
//               });
//             }
//           } catch (error) {
//             console.error('Error parsing chunk data:', error);
//           }

//         }
//       }
//     }
//   }

//   if (buffer.trim()) {
//     try {
//       const data = JSON.parse(buffer.trim().replace('data: data:', ''));
//       const text = data.choices[0]?.delta?.content || '';
//       if (text) {
//         fullAssistantMessage += text;
//         setMessages(prevMessages => {
//           const newMessages = [...prevMessages];
//           newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
//           return newMessages;
//         });
//       }
//     } catch (error) {
//       console.error('Error parsing final buffer data:', error);
//     }
//   }

//   const finalMessages = [...updatedMessages];
//   finalMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
//   setMessages(finalMessages);
//   // setIsRendering(false);

//   sessionStorage.setItem(
//     'chatMessages',
//     JSON.stringify(finalMessages.filter((msg) => msg.role === 'user' || msg.role === 'assistant'))
//   );

//   await updateConversation(fullAssistantMessage, message);
//   if (voiceConsent) {
//     await convertTextToSpeech(fullAssistantMessage, assistantMessageIndex, finalMessages);
//   }
// };


// const handleStreamingResponse = async (response, updatedMessages, message) => {
//     const reader = response.body.getReader();
//     const decoder = new TextDecoder('utf-8');

//     let buffer = '';
//     let fullAssistantMessage = '';
//     let assistantMessageIndex = updatedMessages.length;

//     try {
//         while (true) {
//             const { done, value } = await reader.read();
//             if (done) break;

//             buffer += decoder.decode(value, { stream: true });

//             let boundary = buffer.indexOf('\n');
//             while (boundary !== -1) {
//                 const chunk = buffer.slice(0, boundary).trim();
//                 buffer = buffer.slice(boundary + 1);
//                 boundary = buffer.indexOf('\n');

//                 if (chunk.startsWith('data:')) {
//                     setLoading(false);
//                     await new Promise(resolve => setTimeout(resolve, 90));

//                     const dataStr = chunk.replace(/^data:\s*/, '').trim(); // Remove 'data: ' prefix

//                     if (dataStr) {
//                         // Handle error messages before attempting to parse as JSON
//                         if (dataStr.startsWith("Error:")) {
//                             console.error('Received error from server:', dataStr);
//                             alert('An error occurred while processing your request. Please try again later.'); // Notify user
//                             return; // Exit the function to prevent further processing
//                         }

//                         try {
//                             const data = JSON.parse(dataStr);
//                             const text = data.choices[0]?.delta?.content || '';
//                             if (!isRenderingRef.current) {
//                                 reader.cancel();
//                                 break;
//                             }
//                             if (text) {
//                                 fullAssistantMessage += text;
//                                 setMessages(prevMessages => {
//                                     const newMessages = [...prevMessages];
//                                     newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
//                                     return newMessages;
//                                 });
//                             }
//                         } catch (error) {
//                             console.error('Error parsing chunk data:', error, 'Chunk data:', dataStr);
//                         }
//                     }
//                 }
//             }
//         }

//         // Handle any remaining buffer after the loop
//         if (buffer.trim()) {
//             const dataStr = buffer.trim().replace(/^data:\s*/, '');
//             if (dataStr.startsWith("Error:")) {
//                 console.error('Received error from server in final buffer:', dataStr);
//                 return; // Exit the function
//             }

//             try {
//                 const data = JSON.parse(dataStr);
//                 const text = data.choices[0]?.delta?.content || '';
//                 if (text) {
//                     fullAssistantMessage += text;
//                     setMessages(prevMessages => {
//                         const newMessages = [...prevMessages];
//                         newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
//                         return newMessages;
//                     });
//                 }
//             } catch (error) {
//                 console.error('Error parsing final buffer data:', error, 'Remaining buffer:', buffer);
//             }
//         }

//         const finalMessages = [...updatedMessages];
//         finalMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
//         setMessages(finalMessages);
//         sessionStorage.setItem('chatMessages', JSON.stringify(finalMessages.filter((msg) => msg.role === 'user' || msg.role === 'assistant')));

//         await updateConversation(fullAssistantMessage, message);
//         if (voiceConsent) {
//             await convertTextToSpeech(fullAssistantMessage, assistantMessageIndex, finalMessages);
//         }
//     } catch (error) {
//         console.error('Error handling streaming response:', error);
//         setLoading(false);
//     } finally {
//         reader.releaseLock();
//     }
// };




  // const handleStreamingResponse = async (response, updatedMessages,message) => {
   

    
  //   const reader = response.body.getReader();
  //   const decoder = new TextDecoder('utf-8');
  
  //   let buffer = '';
  //   let fullAssistantMessage = '';
  //   let assistantMessageIndex = updatedMessages.length;

  //   while (true) {
      
  //     const { done, value } = await reader.read();
  //     if (done) break;
  
  //     buffer += decoder.decode(value, { stream: true });
  
  //     let boundary = buffer.indexOf('\n');
  //     while (boundary !== -1) {
  //       const chunk = buffer.slice(0, boundary).trim();
  //       buffer = buffer.slice(boundary + 1);
  //       boundary = buffer.indexOf('\n');
  
  //       if (chunk.startsWith('data:')) {
  //         setLoading(false);
  //         await new Promise(resolve => setTimeout(resolve, 90));

  //         console.log("chunk :- ", chunk)
          
  //         const dataStr = chunk.replace('data: data:', '').trim();
  //         // console.log(dataStr)
  //         if (dataStr) {
  //           try {
  //             // console.log(dataStr)
  //             const data = JSON.parse(dataStr);
  //             const text = data.choices[0]?.delta?.content || '';
  //             if (!isRenderingRef.current) {
  //               reader.cancel();
  //               break;
  //             }
  //             if (text) {
  //               fullAssistantMessage += text;
  //               setMessages(prevMessages => {
  //                 const newMessages = [...prevMessages];
  //                 newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  //                 return newMessages;
  //               });
  //             }
  //           } catch (error) {
  //             console.error('Error parsing chunk data:', error);
  //           }

  //         }
  //       }
  //     }
  //   }
 
  //   if (buffer.trim()) {
  //     try {
  //       const data = JSON.parse(buffer.trim().replace('data: data:', ''));
  //       const text = data.choices[0]?.delta?.content || '';
  //       if (text) {
  //         fullAssistantMessage += text;
  //         setMessages(prevMessages => {
  //           const newMessages = [...prevMessages];
  //           newMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  //           return newMessages;
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error parsing final buffer data:', error);
  //     }
  //   }
  
  //   const finalMessages = [...updatedMessages];
  //   finalMessages[assistantMessageIndex] = { role: 'assistant', content: fullAssistantMessage };
  //   setMessages(finalMessages);
  //   // setIsRendering(false);

  //   sessionStorage.setItem(
  //     'chatMessages',
  //     JSON.stringify(finalMessages.filter((msg) => msg.role === 'user' || msg.role === 'assistant'))
  //   );
  
  //   await updateConversation(fullAssistantMessage, message);
  //   if (voiceConsent) {
  //     await convertTextToSpeech(fullAssistantMessage, assistantMessageIndex, finalMessages);
  //   }
  // };
  const getSessionId = (chatType) => {
    const sessionId = sessionStorage.getItem('sessionId');
    return `${sessionId}-${chatType}`;
  };
  const sessionId = getSessionId(chatType);
  // Function to update conversation
  const updateConversation = async (fullAssistantMessage, message) => {
    // const payload = {
    //   users: [
    //     { user_name: user_name, content: message, role: 'user', chat_type: chatType, session_id: generateSessionId() },
    //     { user_name: user_name, content: fullAssistantMessage, role: 'assistant', chat_type: chatType, session_id: generateSessionId() }
    //   ]
    // };

    // if (message.imageUrl && !message.text) {
    //   // Case 1: Only imageUrl exists
    //   const InputUserMessage = message.imageUrl;
    //   } else if (!message.imageUrl && message.text) {
    //       // Case 2: Only text exists
    //       const InputUserMessage = message.text;
    //   } else if (message.imageUrl && message.text) {
    //       // Case 3: Both imageUrl and text exist
    //       const InputUserMessage =message.imageUrl + ' ' + message.text
    //   }

    let InputUserMessage = '';

    if (message.imageUrl && !message.text) {
      // Case 1: Only imageUrl exists
      InputUserMessage = message.text;
      } else if (!message.imageUrl && message.text) {
          // Case 2: Only text exists
          InputUserMessage = message.text;
      } else if (message.imageUrl && message.text) {
          // Case 3: Both imageUrl and text exist
          InputUserMessage = message.text
      }
  
    const payload = {
      users: [
        { user_name: user_name, content: InputUserMessage, role: 'user', chat_type: chatType, session_id: sessionId },
        { user_name: user_name, content: fullAssistantMessage, role: 'assistant', chat_type: chatType, session_id: sessionId }
      ]
    };

    console.log(payload)
    try {
      const response = await fetch(`${BASE_URL}/api/conversation_update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      if (data.status === 'Success.') {

        console.log('User conversation updated successfully.');
      }
    } catch (error) {
      console.error('Error updating conversation:', error);
    }
    finally{
      sessionStorage.setItem('isRendering',false)

    }
  };
  
  
  // Function to convert text to speech
  const convertTextToSpeech = async (fullAssistantMessage, assistantMessageIndex, finalMessages) => {
    const cleanText = removeEmojis(fullAssistantMessage)
    const ttsPayload = {
      text: cleanText,
      character_voice_code: voice_char,
    };
  
    try {
      const response = await fetch(`${BASE_URL}/api/text-to-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ttsPayload),
      });
  
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
  
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        newMessages[assistantMessageIndex] = {
          ...newMessages[assistantMessageIndex],
          audioUrl: audioUrl,
          display_message: true
        };
        newMessages[assistantMessageIndex - 1] = {
          ...newMessages[assistantMessageIndex - 1],
          display_message: true
        };
        if (chatType === 'fresh') {
          sessionStorage.setItem('freshChat', JSON.stringify(newMessages));
        } else {
          sessionStorage.setItem('previousChat', JSON.stringify(newMessages));
        }
        // setIsRendering(false);
        return newMessages;
      });
    } catch (error) {
      console.error('Error converting text to speech:', error);
      // setIsRendering(false);
    }
  };
  

  const handleFreshChatClick = async () => {
    localStorage.setItem('chatType', 'fresh');
    setLoadingText('Initiating a new chat session. BAIA is currently analyzing your previous interactions. Please be patient as this process may take 20 to 45 seconds.');
  
    let freshChatMessages = sessionStorage.getItem('freshChat');
  
    // Check if freshChatMessages is null, undefined, or an empty string
    if (!freshChatMessages || freshChatMessages.length === 0) {
      console.log('Fetching fresh chat...');
      const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const user_name = userinfo.username;
      const freshEndpoint = sessionStorage.getItem('freshEndpoint'); // Make sure this is set correctly
  
      try {
        setIsLoading(true); // Set loading to true before fetching fresh chat
        freshChatMessages = await fetchFreshChat(user_name, freshEndpoint);
        sessionStorage.setItem('freshChat', JSON.stringify(freshChatMessages));
        // console.log('Fetched fresh chat messages:', freshChatMessages);
      } catch (error) {
        console.error('Error fetching fresh chat messages:', error);
        setLoadingText('Failed to load fresh chat messages. Please try again.');
        setIsLoading(false); // Set loading to false in case of error
        return;
      
      } finally {
        setIsLoading(false); // Ensure loading is set to false after fetching
      }
    }
  
    setMessages(JSON.parse(sessionStorage.getItem('freshChat')));
    setFreshChatActive(true);
    setPreviousChatActive(false); // Reset previous chat active state
  };
  
  

  const handlePreviousChatClick = () => {
    localStorage.setItem('chatType','previous')

    // console.log("ChatType",chatType);
    setLoadingText('BAIA is analyzing previous sessions, please be patient. It may take up to 20-45 seconds...');
   let days = localStorage.getItem('days');
    if (!days) {
      days = 1;
      localStorage.setItem('days', days);
    }
    setMessages(JSON.parse(sessionStorage.getItem('previousChat')))
  
    setPreviousChatActive(true);
    setFreshChatActive(false); 
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Use MUI's breakpoint for mobile view
  // console.log("ChatType",chatType);
  const audioPlayerStyles = {
    marginLeft: isMobile ? '50px' : '65px',
    marginTop: '8px',
    width: isMobile ? '80%' : '50%',
  };


  // const audioPlayerIndices = getAudioPlayerIndices(messages);

  const audioPlayerIndices = getAudioPlayerIndices(messages); // Ensure yourData is correctly initialized

 
  const [editedMessage, setEditedMessage] = useState(null); // Track the edited message
    const [totalTimer, setTotalTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const totalSeconds=JSON.parse(sessionStorage.getItem('availed-time'))
  const hours = Math.floor(totalSeconds / 3600) || 0;
  const minutes = Math.floor((totalSeconds % 3600) / 60) || 0;
  const seconds = totalSeconds % 60 || 0;
 
  const { localTimer, t } = useTimer();
   useEffect(() => {setTotalTime({hours,minutes,seconds}) }, []);


   const handleEditMessage = (index, newMessage) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index].content = newMessage;

      // Remove all messages following the edited message
      const truncatedMessages = updatedMessages.slice(0, index );

      // Set the edited message to trigger the effect
      setEditedMessage(newMessage);

      // Return truncated messages to update the state
      return truncatedMessages;
    });
  };
//   const ImageRenderer = ({ content }) => {
//     console.log("Rendering ImageRenderer with content:", content); // Log the content passed in

//     // Regular expression to find image URLs with file extensions (png, jpg, jpeg, gif)
//     const imageUrlPattern = /https?:\/\/.*\.(?:png|jpg|jpeg|gif)/i;

//     // Additional check for URLs from Googleusercontent (or similar domains)
//     const fallbackImageUrlPattern = /https?:\/\/(?:lh\d+\.googleusercontent\.com|drive\.google\.com|otherimagehost\.com)[^\s]*/i;
    
//     // Match image URL based on the primary pattern or fallback pattern
//     const match = content ? (content.match(imageUrlPattern) || content.match(fallbackImageUrlPattern)) : null;

//     if (match && match[0]) {
//         const imageUrl = match[0];
//         console.log("Image URL matched:", imageUrl); // Log matched URL

//         // Render image if URL is valid
//         return (
//             <img
//                 src={imageUrl} // Use the first match
//                 alt="Image sent by user"
//                 onError={(e) => {
//                     console.log("Image failed to load:", imageUrl);
//                     e.target.style.display = 'none'; // Hide image if it fails to load
//                 }}
//                 style={{
//                     maxWidth: '100%', 
//                     borderRadius: '8px', 
//                     marginTop: '10px',
//                 }}
//             />
//         );
//     } else {
//         console.log("No image URL matched or content is empty"); // Log if no image found
//         return null; // Return null if no match is found, avoiding fallback text
//     }
// };

// const ImageRenderer = ({ user_name, content }) => {
//   const [imageSrc, setImageSrc] = useState(() => {
//     // Initialize state with the cached image if it exists
//     return sessionStorage.getItem(user_name) || null;
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     // Function to fetch the image using the postfixed user name
//     const fetchAndRenderImage = (userName) => {
//       const postfixedUserName = `${userName}_chatimage`; // Generate the image ID using the postfixed user name

//       setLoading(true); // Start loading

//       fetch(`${BASE_URL}/fetch_image?user_name=${postfixedUserName}`)
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.success) {
//             sessionStorage.setItem(userName, data.response); // Cache image URL in sessionStorage
//             setImageSrc(data.response); // Set the fetched image URL
//             setError(false); // No error
//           } else {
//             console.error("No image found for this identifier");
//             setError(true); // Set error state if no image found
//           }
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           setError(true); // Set error state on fetch failure
//         })
//         .finally(() => {
//           setLoading(false); // Stop loading after fetch attempt
//         });
//     };

//     // Check if there's a valid URL in the content
//     const urlRegex = /https?:\/\/[^\s]+/g;
//     const urls = content.match(urlRegex);
//     if (urls && urls.length > 0 && !imageSrc) {
//       fetchAndRenderImage(user_name); // Fetch image only if there are URLs and no image is already loaded
//     }
//   }, [user_name, content, imageSrc]);

//   if (loading) {
//     return <p>Loading image...</p>; // Show loading text while the image is being fetched
//   }

//   if (error) {
//     return <p>Error loading image. Please check the image identifier or API.</p>; // Show error message on failure
//   }

//   return (
//     <div style={{ textAlign: 'right', marginTop: '10px' }}>
//       {imageSrc ? (
//         <img
//           src={imageSrc} // Render the fetched or cached image
//           alt="Uploaded by user"
//           style={{
//             maxWidth: "50%",
//             borderRadius: "8px",
//             marginTop: "10px",
//           }}
//           onError={(e) => {
//             console.log("Image failed to load:", imageSrc);
//             e.target.style.display = "none"; // Hide image if it fails to load
//           }}
//         />
//       ) : (
//         <p>No image available</p> // Handle the case when no image is available
//       )}
//     </div>
//   );
// };

const renderMessage = useCallback((msg, index) => {
  if (!msg || typeof msg !== 'object') {
    console.warn("Invalid message:", msg);
    return null;
  }

  const { display_message, role, content } = msg;


  if (display_message === undefined || display_message) {
    const AutoAudioEnabledFlag = JSON.parse(sessionStorage.getItem('autoAudioEnabled') || 'false');
    console.log("Auto audio enabled:", AutoAudioEnabledFlag); // Debugging line
  
    return (
      <Box sx={{ marginBottom: 2 }} key={index}>
        <ChatMessage
          message={role === 'user' ? content.replace(/https?:\/\/[^\s]+/g, '') : content}
          isUser={role === 'user'}
          loading={load && role === 'assistant' && content === ''}
          onEdit={(updatedMessage) => handleEditMessage(index, updatedMessage)}
        />
        {role === 'user' && content && (
          <ImageRenderer content={content} user_name={user_name} />
        )}
        {role === 'assistant' && audioPlayerIndices.includes(index) && voiceConsent && (
          AutoAudioEnabledFlag ? (
            <CustomAudioPlayerAutoPlay src={msg.audioUrl} />
          ) : (
            <CustomAudioPlayer src={msg.audioUrl} />
          )
        )}
      </Box>
    );
  }
  
  

  // if (display_message === undefined || display_message) {
  //   return (
  //     <Box sx={{ marginBottom: 2 }} key={index}>
  //       <ChatMessage
  //         message={role === 'user' ? content.replace(/https?:\/\/[^\s]+/g, '') : content}
  //         isUser={role === 'user'}
  //         loading={load && role === 'assistant' && content === ''}
  //         onEdit={(updatedMessage) => handleEditMessage(index, updatedMessage)}
  //       />
  //       {role === 'user' && content && (
  //         <ImageRenderer content={content} user_name={user_name} />
  //       )}
  //       {role === 'assistant' && audioPlayerIndices.includes(index) && voiceConsent && (
  //         JSON.parse(sessionStorage.getItem('autoAudioEnabled') || 'false') ? (
  //           <CustomAudioPlayerAutoPlay src={msg.audioUrl} />
  //         ) : (
  //           <CustomAudioPlayer src={msg.audioUrl} />
  //         )
  //       )}

  //       {/* {role === 'assistant' && audioPlayerIndices.includes(index) && voiceConsent && (
  //         isAutoAudioEnabled ? (
  //           <CustomAudioPlayerAutoPlay src={msg.audioUrl} />
  //         ) : (
  //           <CustomAudioPlayer src={msg.audioUrl} />
  //         )
  //       )} */}

  //       {/* {role === 'assistant' && audioPlayerIndices.includes(index) && voiceConsent && (
  //         <CustomAudioPlayerAutoPlay src={msg.audioUrl} />
  //       )} */}
  //       {/* {role === 'assistant' && audioPlayerIndices.includes(index) && voiceConsent && (
  //         <CustomAudioPlayer src={msg.audioUrl} />
  //       )} */}
  //     </Box>
  //   );
  // }

  return null;
}, [audioPlayerIndices, load, voiceConsent, handleEditMessage, user_name]);


return (
  <div className="main-container">
    <NavBar />

    <main className={`final-body ${className}`}>
      <section className="content-background" />
      <section className="body">
        <Header PreviousChat={handlePreviousChatClick} FreshChat={handleFreshChatClick} />

        <div className="main-body">
          {isLoading ? (
            <div className="loading-screen-home">
              <div className="loading-circle-home"></div>
              <div className="load-text">{loadingText}</div>
            </div>
          ) : (
            <div className="chat-body-background" ref={chatBodyRef}>
              {messages && messages.length > 0 ? (
                messages.map((msg, index) => renderMessage(msg, index))
              ) : (
                <p>No messages available.</p>
              )}
            </div>
          )}
        </div>

        <AlertMessage
          showAssessmentAlert={showAssessmentAlert}
          pendingAssessments={pendingAssessments}
          handleCloseAssessmentAlert={handleCloseAssessmentAlert}
          showProfileAlert={showProfileAlert}
          missingProfileData={missingProfileData}
          handleCloseProfileAlert={handleCloseProfileAlert}
          showConsentAlert={showConsentAlert}
          handleCloseConsentAlert={handleCloseConsentAlert}
        />

        <MessageInput onSend={handleSendMessage} isRenderingRef={isRenderingRef} />
      </section>
    </main>
  </div>
);

};

FinalBody.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array.isRequired, // Adjust according to your needs
  handleSendMessage: PropTypes.func.isRequired,
  user_name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string.isRequired,
};

export default FinalBody; // Ensure you're exporting FinalBody correctly


// const ImageRenderer = React.memo(({ user_name, content }) => {
//   const [imageSrc, setImageSrc] = useState(() => {
//     return sessionStorage.getItem(user_name) || null;
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     const urlRegex = /https?:\/\/[^\s]+/g;
//     const urls = content.match(urlRegex);

//     if (!urls || urls.length === 0) {
//       setImageSrc(null);
//       setLoading(false);
//       setError(false);
//       return;
//     }

//     const fetchAndRenderImage = (userName) => {
//       const postfixedUserName = `${userName}_chatimage`;

//       setLoading(true);

//       fetch(`${BASE_URL}/fetch_image?user_name=${postfixedUserName}`)
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.success) {
//             sessionStorage.setItem(userName, data.response);
//             setImageSrc(data.response);
//             setError(false);
//           } else {
//             console.error("No image found for this identifier");
//             setError(true);
//           }
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           setError(true);
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     };

//     if (!imageSrc) {
//       fetchAndRenderImage(user_name);
//     }
//   }, [user_name, content, imageSrc]);

//   if (!imageSrc) return null;
//   if (loading) return <p>Loading image...</p>;
//   if (error) return <p>Error loading image. Please check the image identifier or API.</p>;

//   return (
//     <div style={{ textAlign: 'right', marginTop: '10px' }}>
//       <img
//         src={imageSrc}
//         alt="Uploaded by user"
//         style={{
//           maxWidth: "50%",
//           borderRadius: "8px",
//           marginTop: "10px",
//         }}
//         onError={(e) => {
//           console.log("Image failed to load:", imageSrc);
//           e.target.style.display = "none";
//         }}
//       />
//     </div>
//   );
// });




//   useEffect(() => {
//     if (editedMessage !== null) {
//       // Ensure state update is processed before sending the message
//       handleSendMessage(editedMessage);
//       setEditedMessage(null); // Clear the edited message state
//     }
//   }, [editedMessage]);


//   const renderMessage = useCallback((msg, index) => {
//     if (msg && (msg.display_message === undefined || msg.display_message)) {
//       return (
//         <Box sx={{ marginBottom: 2 }} key={index}>
//           <ChatMessage
//             message={msg.role === 'user' ? msg.content.replace(/https?:\/\/[^\s]+/g, '') : msg.content}
//             isUser={msg.role === 'user'}
//             loading={load && msg.role === 'assistant' && msg.content === ''}
//             onEdit={(updatedMessage) => handleEditMessage(index, updatedMessage)}
//           />

//           {/* Image rendering optimization */}
//           {msg.role === 'user' && msg.content && (
//             <ReactMemo>
//               <ImageRenderer content={msg.content} user_name={user_name} />
//             </ReactMemo>
//           )}

//           {/* Render audio player */}
//           {msg.role === 'assistant' && audioPlayerIndices.includes(index) && voiceConsent && (
//             <CustomAudioPlayer src={msg.audioUrl} />
//           )}
//         </Box>
//       );
//     }

//     return null;
//   }, [audioPlayerIndices, load, voiceConsent, handleEditMessage, user_name]);



//    return (
//     <div className="main-container">
//       <NavBar />

//       <main className={`final-body ${className}`}>
//         <section className="content-background" />
//         <section className="body">
//           <Header
//             PreviousChat={handlePreviousChatClick}
//             FreshChat={handleFreshChatClick}
//           />

//           <div className="main-body">
//             {isLoading ? (
//               <div className="loading-screen-home">
//                 <div className="loading-circle-home"></div>
//                 <div className="load-text">{loadingText}</div>
//               </div>
//             ) : (
//               <div className="chat-body-background" ref={chatBodyRef}>
//                 {/* Use React.memo to prevent unnecessary re-renders */}
//                 {messages && messages.length > 0 ? (
//                   messages.map((msg, index) => renderMessage(msg, index))
//                 ) : (
//                   <p>No messages available.</p>
//                 )}
//               </div>
//             )}
//           </div>

//           <AlertMessage
//             showAssessmentAlert={showAssessmentAlert}
//             pendingAssessments={pendingAssessments}
//             handleCloseAssessmentAlert={handleCloseAssessmentAlert}
//             showProfileAlert={showProfileAlert}
//             missingProfileData={missingProfileData}
//             handleCloseProfileAlert={handleCloseProfileAlert}
//             showConsentAlert={showConsentAlert}
//             handleCloseConsentAlert={handleCloseConsentAlert}
//           />

//           <MessageInput onSend={handleSendMessage} isRenderingRef={isRenderingRef} />
//         </section>
//       </main>
//     </div>
//   );
// };

// FinalBody.propTypes = {
//   className: PropTypes.string,
// };

// export default FinalBody;


// // Export the memoized version of the component
// // export default React.memo(FinalBody);


// // FinalBody.propTypes = {
// //   className: PropTypes.string,
// // };

// // // export default React.memo(FinalBody);
// // // FinalBody.propTypes = {
// // //   className: PropTypes.string,
// // // };

// // export default FinalBody;

